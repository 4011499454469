/* Modal container */
.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.9); /* Brighter gray background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal content */
.sub-modal-content {
  background-color: #f9f9f9; /* Light gray background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: black; /* Text color set to black */
}

/* Button container for horizontal alignment */
.button-container {
  display: flex;
  justify-content: center; /* Center the buttons */
  margin-top: 20px; /* Space above the buttons */
}

/* Button styles */
.sub-modal-content button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 0 10px; /* Add margins to create space between buttons */
}

/* Confirm button style */
.confirm-btn {
  background-color: #28a745;
  color: white;
}

.confirm-btn:visited {
  color: white;
}

/* Cancel button style */
.cancel-btn {
  background-color: #dc3545;
  color: white;
}
