.search-container {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 696px;
  flex-direction: row-reverse; /* keeps your column reverse direction */
}

.search-input {
  width: 200px;
  margin: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #fff;
  background-color: #333;
  transition: width 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #666;
}

.search-icon {
  cursor: pointer;
  margin-left: 8px;
  color: #666; /* Ensure the icon color is visible */
  background-color: #444;
  margin: 20px;
}

.search-icon:hover {
  color: #333; /* Darken the icon on hover for better visibility */
}

.search-icon svg {
  color: #d4d4d4;
}
