.alphabet-nav button {
  background: none;
  border: none;
  color: #d4d4d4;
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

.alphabet-nav button:disabled {
  color: #666;
  cursor: not-allowed;
}

.alphabet-nav button:hover:enabled {
  background-color: #444;
}

.people-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 696px;
  padding: 40px 0;
  margin-top: 20px;
}

.person-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Ensure content is spaced properly */
  align-items: center; /* Align items vertically centered */
  width: 100%;
  padding: 0;
  margin-bottom: 40px; /* Add some margin to separate cards */
  box-sizing: border-box; /* Ensure padding and border are included in the total width and height */
}

.person-image-and-description {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; /* Align items vertically centered */
  gap: 24px;
}

.person-image {
  border-radius: 4px;
  width: 155px; /* Set a fixed width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  flex-grow: 1;
}

.person-description {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center; /* Center the description vertically */
}

.person-description .person-name {
  white-space: pre;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #d4d4d4;
  font-size: 22px;
  line-height: 1.4em;
  text-align: start;
}

.person-description .person-verified {
  white-space: pre;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #d4d4d4;
  font-size: 14px;
  line-height: 1.2em;
  text-align: start;
}

.profile-container {
  align-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  height: auto;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.profile-verified {
  position: relative;
  padding-left: 0; /* Remove the padding-left to avoid extra space */
  display: inline-flex;
  align-items: center; /* Center the items vertically */
}

.profile-verified::before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)" style="user-select: none; width: 12px; height: 12px; display: inline-block; fill: rgb(255, 255, 255); color: rgb(255, 255, 255); flex-shrink: 0;"><g color="rgb(255, 255, 255)" weight="regular"><path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path></g></svg>');
  margin-right: 4px; /* Adjust the space between the icon and text */
  vertical-align: middle;
  width: 12px; /* Set width to 12px */
  height: 12px; /* Set height to 12px */
}

.profile-picture {
  width: 100%;
  max-width: 328px;
  flex: 1;
  min-width: 0;
  height: auto;
  border-radius: 18px;
  margin-right: 20px;
}

.profile-details {
  flex: 1;
  max-width: 328px;
  min-width: 0;
}

.profile-details h1 {
  font-size: 48px;
  font-family: 'Archivo', 'Archivo Placeholder', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #d4d4d4;
  letter-spacing: 0;
  text-transform: capitalize;
  text-decoration: none;
  line-height: 1.2em;
  text-align: center;
}

.profile-details .suremark-code {
  font-size: 18px;
}

@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;
  }

  .profile-details h1 {
    font-size: 32px; /* Adjust font size for better fit */
  }

  .profile-picture {
    margin-right: 0;
  }
}
