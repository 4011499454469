.top-space {
  display: flex;
  align-items: center;
  margin: 24px 0;
  max-width: 1100px;
}

.highlighted-asset {
  border: 3px solid green;
}
